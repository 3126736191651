import { makeStyles } from '@material-ui/core/styles'

export const stopsListComponentStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    cursor: 'pointer',
    [theme.breakpoints.down(550)]: {
      // height: '100%',
    },
  },
  appBarIcon: {
    fontSize: 24,
    color: theme.palette.base.white,
  },
  busStopText: {
    fontSize: 14,
    color: theme.palette.base.red,
    marginLeft: 10,
    marginRight: 10,
  },
  busStopSecondaryText: {
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10,
    color: theme.palette.base.red,
  },
  listItemIcon: {
    fontSize: 24,
    color: theme.palette.base.white,
  },
  favoriteIcon: {
    color: '#979797',
    fontSize: 24,
  },
  favoriteIconSelected: {
    color: '#cc0033',
    fontSize: 24,
  },
  iconButtonBorder: {
    position: 'relative',
    zIndex: 1000,
    fontSize: '24px',
    color: theme.color.rutgersRed,
  },
}))
