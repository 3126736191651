import { makeStyles } from '@material-ui/core/styles'

// TODO Bring Back Unique Colors
export const MyPaycheckStyles = makeStyles((theme) => ({
  content: {
    textAlign: 'center',
    margin: 0,
    padding: 0,
    height: 475,
    overflowY: 'auto',
  },
  paymentInfoContainer: {
    marginLeft: 10,
  },
  divider: {
    height: 2,
    background: theme.palette.secondary.headerText,
  },
  icon: {
    color: theme.palette.secondary.headerText,
    fontSize: 40,
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  paymentSpan: {
    color: theme.palette.secondary.headerText,
  },
  paymentColorsLeft: {
    backgroundColor: 'green',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    height: 40,
    marginRight: 1,
    '&:disabled': {
      backgroundColor: 'green',
      opacity: 0.5,
    },
    '&:hover': {
      backgroundColor: 'green',
      filter: 'brightness(75%)',
    },
  },
  paymentColorsRight: {
    backgroundColor: 'green',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 40,
    marginLeft: 1,
    '&:disabled': {
      backgroundColor: 'green',
      opacity: 0.5,
    },
    '&:hover': {
      backgroundColor: 'green',
      filter: 'brightness(75%)',
    },
  },
  fullwidth: {
    width: '100%',
  },
  iconButton: {
    margin: '10px 0',
  },
}))

export default MyPaycheckStyles
