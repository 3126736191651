import React from 'react'

import {
  Card,
  Typography,
  Grid,
  Box,
  Divider,
  IconButton,
} from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { useHistory } from 'react-router-dom'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import styles from '../settingsStyles'
import PageLink from '../components/PageLink'
import IconPicker from '../../apps/AppComponents/IconPicker/IconPicker'
import CheckIsMobile from '../../../../utils/isMobileHook'

function AppSupport() {
  const isMobile = CheckIsMobile()
  const classes = styles()
  const history = useHistory()
  const FAQurl =
    'https://ithelp.rutgers.edu/sp?id=kb_category&kb_id=6bd412391bfdc0108e4585d7cc4bcb9d&kb_category=6f30205b1bc680908e4585d7cc4bcb15'
  const vidurl = 'https://it.rutgers.edu/myrutgers/'
  return (
    <Box className={classes.centerPage}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.sectionTitle}
        >
          <IconButton
            onClick={() => history.replace('/settings')}
            className={classes.backButton}
            aria-label="Back"
          >
            <ArrowBackIos fontSize="small" />
          </IconButton>
          <Typography variant="h1">
            <b>App Support</b>
          </Typography>
        </Grid>
        {isMobile ? (
          <Box sx={{ width: '100%' }}>
            <Grid
              container
              direction="column"
              alignItems="center"
              sx={{ width: '100%' }}
              spacing={2}
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Card className={classes.supportHelpDeskCard}>
                  <section>
                    <List style={{ flex: 1 }}>
                      <ListItem className={classes.supportListItem}>
                        <Typography
                          variant="h4"
                          style={{ fontWeight: 'bold', paddingTop: 5 }}
                        >
                          Call Us
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <IconPicker
                          iconName="fa-phone"
                          size="3x"
                          color="#c41f32"
                        />
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <b>Toll-Free Number: </b>
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <a href="tel:833-648-4357">833-OIT-HELP</a>
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          (833-648-4357)
                        </Typography>
                      </ListItem>
                    </List>
                  </section>
                </Card>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Card className={classes.supportHelpDeskCard}>
                  <section>
                    <List style={{ flex: 1 }}>
                      <ListItem className={classes.supportListItem}>
                        <Typography
                          variant="h4"
                          style={{ fontWeight: 'bold', paddingTop: 5 }}
                        >
                          Email Us
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <IconPicker
                          iconName="fa-envelope-open"
                          size="3x"
                          color="#c41f32"
                        />
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <b>New Brunswick: </b>
                          <a href="mailto:help@oit.rutgers.edu">
                            help@oit.rutgers.edu
                          </a>
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <b>RBHS: </b>
                          <a href="mailto:facstaffhelp@oit.rutgers.edu">
                            facstaffhelp@oit.rutgers.edu
                          </a>
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <b>Newark: </b>
                          <a href="mailto:help@newark.rutgers.edu">
                            {' '}
                            help@newark.rutgers.edu
                          </a>
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <b>Camden: </b>
                          <a href="mailto:help@camden.rutgers.edu">
                            help@camden.rutgers.edu
                          </a>
                        </Typography>
                      </ListItem>
                    </List>
                  </section>
                </Card>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Card className={classes.supportHelpDeskCard}>
                  <section>
                    <List style={{ flex: 1 }}>
                      <ListItem className={classes.supportListItem}>
                        <Typography
                          variant="h4"
                          style={{ fontWeight: 'bold', paddingTop: 5 }}
                        >
                          Call Us
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <IconPicker
                          iconName="fa-phone"
                          size="3x"
                          color="#c41f32"
                        />
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <b>Toll-Free Number: </b>
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <a href="tel:833-648-4357">833-OIT-HELP</a>
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          (833-648-4357)
                        </Typography>
                      </ListItem>
                    </List>
                  </section>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.supportHelpDeskCard}>
                  <section>
                    <List style={{ flex: 1 }}>
                      <ListItem className={classes.supportListItem}>
                        <Typography
                          variant="h4"
                          style={{ fontWeight: 'bold', paddingTop: 5 }}
                        >
                          Email Us
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <IconPicker
                          iconName="fa-envelope-open"
                          size="3x"
                          color="#c41f32"
                        />
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <b>New Brunswick: </b>
                          <a href="mailto:help@oit.rutgers.edu">
                            help@oit.rutgers.edu
                          </a>
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <b>RBHS: </b>
                          <a href="mailto:facstaffhelp@oit.rutgers.edu">
                            facstaffhelp@oit.rutgers.edu
                          </a>
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <b>Newark: </b>
                          <a href="mailto:help@newark.rutgers.edu">
                            {' '}
                            help@newark.rutgers.edu
                          </a>
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.supportListItem}>
                        <Typography className={classes.supportInfoItem}>
                          <b>Camden: </b>
                          <a href="mailto:help@camden.rutgers.edu">
                            help@camden.rutgers.edu
                          </a>
                        </Typography>
                      </ListItem>
                    </List>
                  </section>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <PageLink
              title="FAQ"
              description="3000"
              link={() => window.open(FAQurl, '_blank')}
            />
            <Divider variant="middle" className={classes.fullWidth} />
            <PageLink
              title="Portal Tutorial Videos"
              description="Useful info here."
              link={() => window.open(vidurl, '_blank')}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AppSupport
