import React from 'react'

import { Typography, Grid, Box, IconButton, Card } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import styles from '../settingsStyles'
import { credits } from './credits.constants'

function Credits() {
  const classes = styles()
  const history = useHistory()
  return (
    <Box className={classes.centerPage}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.sectionTitle}
        >
          <IconButton
            onClick={() => history.push('/settings')}
            className={classes.backButton}
            aria-label="Back"
          >
            <ArrowBackIos fontSize="small" />
          </IconButton>
          <Typography variant="h1">
            <b>Credits</b>
          </Typography>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={2}
              className={classes.creditsCard}
            >
              <Grid item>
                <Typography variant="h2">Meet the myRutgers team</Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={2}
                  xs={4}
                >
                  <Grid item>
                    <Typography variant="h4">Mobile</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {credits.mobile.map((credit) => (
                      <Typography variant="body1" key={credit}>
                        {credit}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  xs={4}
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Grid item>
                    <Typography variant="h4">Web</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {credits.web.map((credit) => (
                      <Typography variant="body1" key={credit}>
                        {credit}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  xs={4}
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Grid item>
                    <Typography variant="h4">Backend</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {credits.backend.map((credit) => (
                      <Typography variant="body1" key={credit}>
                        {credit}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Typography variant="h4">System Architect</Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                {credits.systemArchitect.map((credit) => (
                  <Typography variant="body1" key={credit}>
                    {credit}
                  </Typography>
                ))}
              </Grid>
              <Grid item>
                <Typography variant="h4">Past Contributors</Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                {credits.pastContributors.map((credit) => (
                  <Typography variant="body1" key={credit}>
                    {credit}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Credits
