import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useSelector } from 'react-redux'
import { selectShowSensitiveInfo } from '../../../settings/settingsSlice'

import styles from './HiddenEyeButtonTwoStyles'
import { logDashboardEvent } from '../../events'

function HiddenEyeButtonTwo({
  label,
  value,
  showUsedValue,
  widgetId,
  dispatch,
  backgroundColor,
}) {
  // TODO Connect the sensitive info button to the universal one
  // const showSensitiveInfo = useThemeSelector((s) => s.showSensitiveInfo)
  const classes = styles()
  const showSensitiveInfo = useSelector(selectShowSensitiveInfo)
  const [showData, setShowData] = React.useState(showSensitiveInfo)

  React.useEffect(() => {
    // Detects change in showSensitiveInfo and changes showData to that
    setShowData(showSensitiveInfo)
  }, [showSensitiveInfo])

  const clickHandler = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        !showData ? 'Show' : 'Hide',
        label,
      )
    }
    setShowData(!showData)
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Box
        className={classes.ringContainer}
        style={{
          background: backgroundColor || getBackgroundColor(label),
        }}
        onClick={clickHandler}
      >
        {showSensitiveInfo || showData ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.dataContainer}
          >
            <Typography className={classes.valueHover}>{value}</Typography>
            {showUsedValue && (
              <Typography
                align="center"
                variant="subtitle2"
                className={classes.usedLabelText}
              >
                {showUsedValue} used
              </Typography>
            )}
          </Grid>
        ) : (
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            className={classes.dataContainer}
          >
            <VisibilityOff className={classes.visibilityOff} />
          </Grid>
        )}
        <Grid
          style={{
            background: 'white',
            borderBottomRightRadius: '1rem',
            borderBottomLeftRadius: '1rem',
            width: 90,
            height: 34,
            marginTop: 7,
            marginLeft: 5,
            paddingTop: 2,
            paddingBottom: 2,
          }}
          container
          item
          direction="column"
          alignItems="center"
        >
          {label?.split(' ').map((labelName) => (
            <Typography
              align="center"
              variant="subtitle2"
              className={classes.labelText}
              key={labelName}
            >
              {labelName}
            </Typography>
          ))}
        </Grid>
      </Box>
    </Grid>
  )
}

function getBackgroundColor(labelName) {
  switch (labelName) {
    case 'Vacation Days':
      return '#00b339'
    case 'Sick Days':
      return '#8E0D18'
    case 'Administrative Leave':
      return '#007FAC'
    case 'Personal Holiday':
      return '#444444'
    case 'Mandatory Leave':
      return '#666666'
    case 'Paid Leave':
      return '#888888'
    default:
      return '#00b339'
  }
}

export default HiddenEyeButtonTwo
