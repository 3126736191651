import React from 'react'

import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import Box from '@material-ui/core/Box'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import { IconButton } from '@material-ui/core'
import Card from '@material-ui/core/Card'

import { deviceDetect } from 'react-device-detect'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import settingsStyles from '../settingsStyles'

import { sendFeedback, selectUserData, fetchUser } from '../settingsSlice'

// TODO Navigating to settings should show you as on the dashboard,
// myApps, News, Maps tab and highlight that as the active tab
// This should be done after SideNav rework.
function Feedback() {
  const TITLE_LIMIT = 50
  const DESCRIPTION_LIMIT = 600
  const classes = settingsStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const userData = useSelector(selectUserData)
  const [focusedTitle, setFocusedTitle] = React.useState(false)
  const [focusedDescription, setFocusedDescription] = React.useState(false)
  const [colorTitle, setColorTitle] = React.useState('')
  const [colorDesc, setColorDesc] = React.useState('')
  const [titleCharCount, setTitleCharCount] = React.useState(0)
  const [descriptionCharCount, setDescriptionCharCount] = React.useState(0)
  const [feedbackCategory, setFeedback] = React.useState('Enhancement')
  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [alert, setAlert] = React.useState('default')
  const [showAlert, setShowAlert] = React.useState(false)
  const [showSubmitting, setShowSubmitting] = React.useState(false)

  React.useEffect(() => {
    dispatch(fetchUser())
  }, [])

  const feedbackOptions = [
    {
      value: 'Enhancement',
      label: 'Enhancement',
    },
    {
      value: 'Issue',
      label: 'Issue',
    },
    {
      value: 'General',
      label: 'General',
    },
  ]
  const handleFocusTitle = () => {
    setFocusedTitle(true)
  }
  const handleBlurTitle = () => {
    setFocusedTitle(false)
  }
  const handleFocusDesc = () => {
    setFocusedDescription(true)
  }
  const handleBlurDesc = () => {
    setFocusedDescription(false)
  }

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value)
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value.substring(0, TITLE_LIMIT))
    setTitleCharCount(event.target.value.substring(0, TITLE_LIMIT).length)

    if (event.target.value.length >= TITLE_LIMIT - 5) setColorTitle('primary')
    else setColorTitle('')
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value.substring(0, DESCRIPTION_LIMIT))
    setDescriptionCharCount(
      event.target.value.substring(0, DESCRIPTION_LIMIT).length,
    )
    if (event.target.value.length >= DESCRIPTION_LIMIT - 5)
      setColorDesc('primary')
    else setColorDesc('')
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setShowSubmitting(true)
    setShowAlert(false)
    const email = userData ? userData.email : 'error'
    const usersDevice = deviceDetect()
    const device = `${usersDevice.osName} ${usersDevice.osVersion}`
    const osversion = `${usersDevice.browserName} ${usersDevice.browserMajorVersion}`
    const body = {
      appversion: 'Web',
      category: feedbackCategory,
      date: moment().format('MMMM Do YYYY, h:mm:ss a'),
      description,
      device,
      email,
      osversion,
      title,
    }
    dispatch(sendFeedback(body))
      .then(() => {
        setShowAlert(true)
        setShowSubmitting(false)
        setAlert({
          severity: 'success',
          title: 'Success',
          message: 'Your feedback has been sent!',
        })
        setTitle('')
        setDescription('')
      })
      .catch(() => {
        setShowAlert(true)
        setShowSubmitting(false)
        setAlert({
          severity: 'error',
          title: 'Error',
          message:
            'Something went wrong submitting your feedback. Please try again.',
        })
        setTitle('')
        setDescription('')
      })
  }

  return (
    <Box className={classes.centerPage}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.sectionTitle}
        >
          <IconButton
            onClick={() => history.push('/settings')}
            className={classes.backButton}
            aria-label="Back"
          >
            <ArrowBackIos fontSize="small" />
          </IconButton>
          <Typography variant="h1">
            <b>Feedback</b>
          </Typography>
        </Grid>
        {showAlert ? (
          <Alert
            severity={alert.severity}
            onClose={() => {
              setShowAlert(false)
            }}
          >
            <AlertTitle>{alert.title}</AlertTitle>
            {alert.message}
          </Alert>
        ) : (
          <> </>
        )}
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.sectionTitle}
        >
          <Typography variant="h3">
            <b>How are we doing?</b>
          </Typography>
        </Grid>
        <Card className={classes.linksCard}>
          <section className={classes.contentPadding}>
            {showSubmitting ? (
              <CircularProgress />
            ) : (
              <form
                autoComplete="off"
                className={classes.feedbackFormContainer}
                onSubmit={handleSubmit}
              >
                {/* This button allows it so the user can hit the enter key and not submit the form accidentally. */}
                <button
                  type="submit"
                  disabled
                  style={{ display: 'none' }}
                  aria-hidden="true"
                ></button>
                <TextField
                  id="feedback-option"
                  select
                  label="Select"
                  value={feedbackCategory}
                  onChange={handleFeedbackChange}
                  fullWidth
                  margin="normal"
                >
                  {feedbackOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="descriptive-title"
                  label="Title"
                  placeholder="Title"
                  fullWidth
                  margin="normal"
                  value={title}
                  onChange={handleTitleChange}
                  onFocus={handleFocusTitle}
                  onBlur={handleBlurTitle}
                />
                {focusedTitle && titleCharCount / TITLE_LIMIT >= 0.8 ? (
                  <Typography variant="body2" color={colorTitle}>
                    {TITLE_LIMIT - titleCharCount}
                  </Typography>
                ) : (
                  <></>
                )}
                <TextField
                  id="descriptive-issue"
                  label="Description"
                  placeholder="Description"
                  multiline
                  fullWidth
                  margin="normal"
                  value={description}
                  onChange={handleDescriptionChange}
                  onFocus={handleFocusDesc}
                  onBlur={handleBlurDesc}
                />
                {focusedDescription &&
                descriptionCharCount / DESCRIPTION_LIMIT >= 0.8 ? (
                  <Typography variant="body2" color={colorDesc}>
                    {DESCRIPTION_LIMIT - descriptionCharCount}
                  </Typography>
                ) : (
                  <></>
                )}

                <div className={classes.feedbackFormButtonContainer}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ fontWeight: '700', fontSize: '14px' }}
                    size="large"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </section>
        </Card>
      </Grid>
    </Box>
  )
}

export default Feedback
