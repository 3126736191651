import React from 'react'

import {
  Card,
  Typography,
  Grid,
  Box,
  Divider,
  IconButton,
} from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router-dom'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import styles from '../settingsStyles'
import PageLink from '../components/PageLink'

function AppInfo() {
  const classes = styles()
  const history = useHistory()
  const { path } = useRouteMatch()

  const handleLinkPress = (link) => {
    history.push(`${path}${link}`)
  }

  return (
    <Box className={classes.centerPage}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.sectionTitle}
        >
          <IconButton
            onClick={() => history.replace('/settings')}
            aria-label="Back"
            className={classes.backButton}
          >
            <ArrowBackIos />
          </IconButton>
          <Typography variant="h1">
            <b>App Info</b>
          </Typography>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <PageLink
              title="Copyright Information"
              link={() => handleLinkPress('/copyrights')}
              path="/copyrights"
            />
            <Divider variant="middle" className={classes.fullWidth} />
            <PageLink
              title="Privacy Notices"
              link={() => handleLinkPress('/privacyNotices')}
              path="/privacyNotices"
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AppInfo
