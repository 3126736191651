import { makeStyles } from '@material-ui/core/styles'

export const widgetMockupStyles = makeStyles((theme) => ({
  widgetMockUpContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '60px 55px 0 95px',
    minHeight: '100vh',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 20px 0 20px',
    },
  },
  content: {
    padding: 0,
  },
  gridContainer: {
    paddingRight: 10,
    paddingLeft: 10,
    height: 475,
    overflowY: 'clip',
    margin: 0,
    width: 335,
  },
  categoriesList: {
    listStyleType: 'none',
    padding: '0px',
  },
}))

export default widgetMockupStyles
