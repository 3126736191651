/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable react/no-danger */
import React from 'react'
import DOMPurify from 'dompurify'
import Card from '@material-ui/core/Card'
import ButtonBase from '@material-ui/core/ButtonBase'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import ErrorIcon from '@material-ui/icons/Error'
import SchoolIcon from '@material-ui/icons/School'
import InfoIcon from '@material-ui/icons/Info'
import ComputerIcon from '@material-ui/icons/Computer'
import Badge from '@material-ui/core/Badge'
import { useDispatch } from 'react-redux'

import styles from './AlertStyles'
import { acknowledgeAlert } from '../../features/dashboard/widgets/MyAlerts/MyAlertsSlice'
import { logDashboardEvent } from '../../features/dashboard/events'

function Alert({ alert }) {
  const [open, setOpen] = React.useState(false)
  const firstDate =
    alert?.sent_date?.split('T')[0] ||
    alert?.start_date?.split('T')[0] ||
    alert?.createDate?.split('T')[0]
  const [year, month, day] = firstDate?.split('-')
  const longOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const date = new Date(year, month - 1, day)
  const classes = styles()
  const getIcon = () => {
    if (alert.alerttype === 'StudentAlert') {
      return (
        <SchoolIcon
          className={classes[`icon${alert.severityLevel}Alert`] ?? classes.icon}
        />
      )
    }
    if (alert.alerttype === 'EmergencyAlert') {
      return (
        <ErrorIcon
          className={
            classes[`icon${alert.severityLevel}Alert`] ?? classes.emergencyIcon
          }
        />
      )
    }
    if (alert.alerttype === 'ITAlert') {
      return <ComputerIcon className={classes.iconITAlertAlert} />
    }
    return (
      <InfoIcon
        className={
          classes[`icon${alert.severityLevel}Alert`] ??
          classes[`icon${alert.alerttype}Alert`]
        }
      />
    )
  }

  return (
    <Card variant="outlined" className={classes.cardSpacing}>
      <ButtonBase
        focusRipple
        onClick={() => setOpen(true)}
        className={
          alert.alerttype === 'EmergencyAlert' ||
          alert.alerttype === 'StudentAlert' ||
          alert.alerttype === 'EmployeeAlert'
            ? classes.emergencyButton
            : classes.buttonbase
        }
      >
        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          className={classes.verticalGrid}
        >
          <Grid item className={classes.iconHolder}>
            <Grid container direction="column" alignItems="center">
              {getIcon()}
            </Grid>
          </Grid>
          <Grid item className={classes.titleWidth}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              className={classes.fullWidth}
            >
              <Grid item container direction="column" xs>
                <Badge
                  variant="dot"
                  invisible={
                    alert.acknowledgedTS !== '' ||
                    alert.alerttype === 'EmergencyAlert'
                  }
                  className={classes.badgeStyle}
                >
                  <Grid item className={classes.fullWidth}>
                    <Typography
                      noWrap
                      variant="body1"
                      className={
                        alert.alerttype === 'EmergencyAlert' ||
                        alert.alerttype === 'StudentAlert' ||
                        alert.alerttype === 'EmployeeAlert'
                          ? classes.emergencyTitle
                          : classes.title
                      }
                    >
                      {/* title of the alert */}
                      {alert.title}
                    </Typography>
                  </Grid>
                </Badge>
                <Grid item className={classes.fullWidth}>
                  <Typography
                    noWrap
                    variant="caption"
                    className={
                      alert.alerttype === 'EmergencyAlert' ||
                      alert.alerttype === 'StudentAlert' ||
                      alert.alerttype === 'EmployeeAlert'
                        ? classes.emergencyTitle
                        : classes.title
                    }
                  >
                    {/* date of the alert */}
                    {date.toLocaleDateString('en-US', longOptions)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ButtonBase>
      <AlertDialog
        open={open}
        alert={alert}
        dialogHandler={setOpen}
        classes={classes}
      />
    </Card>
  )
}

function AlertDialog({ open, alert, dialogHandler, classes }) {
  const dispatch = useDispatch()
  const firstDate =
    alert?.sent_date?.split('T')[0] ||
    alert?.start_date?.split('T')[0] ||
    alert?.createDate?.split('T')[0]
  const firstDateACK = alert?.acknowledgedTS?.split('T')[0] || ''
  const [year, month, day] = firstDate?.split('-')
  const [yearACK, monthACK, dayACK] = firstDateACK?.split('-')
  const longOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const date = new Date(year, month - 1, day)
  let dateACK
  if (alert?.acknowledgedTS?.includes('T')) {
    dateACK = new Date(yearACK, monthACK - 1, dayACK)
  } else if (alert?.acknowledgedTS && alert?.acknowledgedTS !== '') {
    const parts = alert?.acknowledgedTS?.split(' ')
    const datePart = parts[0]
    const timePart = parts[1]

    const dateParts = datePart.split('-')
    const yearInt = parseInt(dateParts[0])
    const monthInt = parseInt(dateParts[1]) - 1 // Months are zero-indexed
    const dayInt = parseInt(dateParts[2])

    const timeParts = timePart.split(':')
    const hour = parseInt(timeParts[0])
    const minute = parseInt(timeParts[1])

    const secondsAndMilliseconds = timeParts[2].split('.')
    const second = parseInt(secondsAndMilliseconds[0])
    const millisecond = parseInt(secondsAndMilliseconds[1])

    dateACK = new Date(
      yearInt,
      monthInt,
      dayInt,
      hour,
      minute,
      second,
      millisecond,
    )
  }
  const closeDialogAction = () => {
    logDashboardEvent(dispatch, 'alertsDismissEvent', 'AlertItem', alert.dockey)
    dialogHandler(false)
  }
  const acknowledgeDialogAction = () => {
    logDashboardEvent(
      dispatch,
      'alertsAcknowledgeEvent',
      'AlertItem',
      alert.dockey,
    )
    dispatch(acknowledgeAlert(alert))
    dialogHandler(false)
  }
  return (
    <Dialog open={open} onClose={() => dialogHandler(false)}>
      <DialogTitle
        classes={{
          root: alert.severityLevel
            ? classes[`dialogTitle${alert.severityLevel}Alert`]
            : alert.alerttype === 'EmergencyAlert'
            ? classes.dialogTitleHighAlert
            : classes[`dialogTitle${alert.alerttype}Alert`],
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Grid item container direction="column" wrap="nowrap" xs spacing={1}>
            <Grid item>
              <Typography variant="h4" align="center">
                {alert.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <Typography variant="subtitle1" className={classes.alertText}>
            Posted{' '}
            <span className={classes.createDateSpan}>
              {date.toLocaleDateString('en-US', longOptions)}
            </span>
          </Typography>
          {alert.alerttype !== 'EmergencyAlert' &&
          alert.alerttype !== 'ITAlert' ? (
            <Typography variant="subtitle1" className={classes.alertText}>
              Acknowledged{' '}
              <span className={classes.isAcknowledgedSpan}>
                {alert.acknowledgedTS === ''
                  ? 'Not yet Acknowledged'
                  : 'Acknowledged'}
              </span>
            </Typography>
          ) : null}
          {/* <Typography variant="subtitle1" className={classes.alertText}>
            Title <span className={classes.titleSpan}>{alert.title}</span>
          </Typography> */}
          {alert.link ? (
            <Typography variant="subtitle1" className={classes.alertText}>
              Link Title{' '}
              <a
                href={alert.link}
                rel="noopener noreferrer"
                target="_blank"
                className={classes.linkTitleSpan}
              >
                {alert.link}
              </a>
            </Typography>
          ) : null}
          <div
            // eslint-disable-next-line react/no-danger
            className={classes.descriptionStyle}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(alert.message, {
                ADD_ATTR: ['target'],
              }),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex={1}>
          <Button
            autoFocus
            onClick={closeDialogAction}
            variant="outlined"
            className={classes.alertActionButton}
          >
            Close
          </Button>
          {alert.acknowledgedTS === '' ? (
            alert.alerttype !== 'EmergencyAlert' &&
            alert.severityLevel !== 'low' && (
              <Button
                onClick={acknowledgeDialogAction}
                className={
                  alert.severityLevel
                    ? classes[`dialogTitle${alert.severityLevel}Alert`]
                    : alert.alerttype === 'EmergencyAlert'
                    ? classes.dialogTitleHighAlert
                    : classes[`dialogTitle${alert.alerttype}Alert`]
                }
                variant="outlined"
              >
                Acknowledge
              </Button>
            )
          ) : alert.alerttype !== 'EmergencyAlert' &&
            alert.alerttype !== 'ITAlert' ? (
            <Button
              disabled
              className={
                alert.severityLevel
                  ? classes[`dialogTitle${alert.severityLevel}Alert`]
                  : alert.alerttype === 'EmergencyAlert'
                  ? classes.dialogTitleHighAlert
                  : classes[`dialogTitle${alert.alerttype}Alert`]
              }
              variant="outlined"
            >
              Acknowledged {dateACK.toLocaleDateString('en-US')}
            </Button>
          ) : null}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default Alert
