/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  title: 'My Paycheck Mock Up',
  items: [],
  netPay: '',
  payDate: '',
  payPeriodStart: '',
  payPeriodEnd: '',
  status: 'idle',
}

export const fetchMyPaycheckMockup = createAsyncThunk(
  'fetchMyPaycheckMockup',
  async (params) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      `${process.env.REACT_APP_PAYCHECK_MOCKUP_ENDPOINT}?PersonNumber=${params.personNumber}`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

export const paycheckSliceMockup = createSlice({
  name: 'myPaycheckSliceMockup',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMyPaycheckMockup.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyPaycheckMockup.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyPaycheckMockup.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.title = action.payload.title
      state.items = action.payload.items
      state.netPay = action.payload.netPay
      state.payDate = action.payload.payDate
      state.payPeriodStart = action.payload.payPeriodStart
      state.payPeriodEnd = action.payload.payPeriodEnd
      state.iconList = action.payload.iconLinks
    },
  },
})
export const selectPaycheckTitle = (state) => state.paycheckMockup.title
export const selectPaycheckNetPay = (state) => state.paycheckMockup.netPay
export const selectPaycheckItems = (state) => state.paycheckMockup.items
export const selectPaycheckPayDate = (state) => state.paycheckMockup.payDate
export const selectPaycheckPayPeriodStart = (state) =>
  state.paycheckMockup.payPeriodStart
export const selectPaycheckPayPeriodEnd = (state) =>
  state.paycheckMockup.payPeriodEnd
export const selectIconList = (state) => state.paycheckMockup.iconList

export const selectPaycheckStatus = (state) => state.paycheckMockup.status

export default paycheckSliceMockup.reducer
