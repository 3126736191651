/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchMyBenefitsTwo = createAsyncThunk(
  'fetchMyBenefitsTwo',
  async (params) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      `${process.env.REACT_APP_MYBENEFITS_TWO_ENDPOINT}?PersonNumber=${params.personNumber}`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

const initialState = {
  status: 'idle',
  data: {
    title: 'My Benefits',
    summary: [],
    links: [],
  },
}

const myBenefitsTwoSlice = createSlice({
  name: 'myBenefitsSliceTwo',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMyBenefitsTwo.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyBenefitsTwo.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyBenefitsTwo.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
    },
  },
})

export const selectMyBenefitsTwoData = (state) => state.myBenefitsTwo.data
export const selectMyBenefitsTwoStatus = (state) => state.myBenefitsTwo.status

export default myBenefitsTwoSlice.reducer
