import { Box, Button, TextField } from '@material-ui/core'
import React from 'react'
import widgetMockupStyles from './widgetMockupStyles'
import MyPaycheckMockUp from '../../widgets/MyPayCheckMockUp/MyPayCheckMockUp'
import MyBenefitsTwo from '../../widgets/MyBenefitsTwo/MyBenefitsTwo'

export default function WidgetMockups() {
  const [paycheckEmpId, updatePaycheckEmpId] = React.useState(null)
  const [empId, updateEmpId] = React.useState(null)
  const [benefitsEmpId, updateBenefitsEmpId] = React.useState(null)
  const [benefitsId, updateBenefitsId] = React.useState(null)
  const classes = widgetMockupStyles()
  const handlePaycheckEmployeeId = (e) => {
    updatePaycheckEmpId(e.target.value)
  }
  const handleUpdateEmpIdStatus = () => {
    updateEmpId(paycheckEmpId)
  }
  const handleBenefitsEmployeeId = (e) => {
    updateBenefitsEmpId(e.target.value)
  }
  const handleUpdateBenefitesIdStatus = () => {
    updateBenefitsId(benefitsEmpId)
  }
  return (
    <Box className={classes.widgetMockUpContainer}>
      <Box display="flex" flexDirection="column" margin="5px">
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '10px',
            padding: 10,
          }}
        >
          <TextField
            id="paycheck-field"
            label="Paycheck Employee ID"
            variant="outlined"
            type="number"
            size="small"
            onChange={handlePaycheckEmployeeId}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginLeft: 10 }}
            onClick={handleUpdateEmpIdStatus}
          >
            Update
          </Button>
        </Box>
        <MyPaycheckMockUp employeeId={empId} />
      </Box>
      <Box display="flex" flexDirection="column" margin="5px">
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '10px',
            padding: 10,
          }}
        >
          <TextField
            id="benefits-field"
            label="Benefits Employee ID"
            variant="outlined"
            type="number"
            size="small"
            onChange={handleBenefitsEmployeeId}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginLeft: 10 }}
            onClick={handleUpdateBenefitesIdStatus}
          >
            Update
          </Button>
        </Box>
        <MyBenefitsTwo employeeId={benefitsId} />
      </Box>
    </Box>
  )
}
