import React from 'react'

import {
  Card,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { useHistory } from 'react-router-dom'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import { useDispatch, useSelector } from 'react-redux'
import RefreshIcon from '@material-ui/icons/Refresh'

import { Button } from 'semantic-ui-react'
import styles from '../settingsStyles'
import Loading from '../../dashboard/widgets/helpers/Loading'

import {
  fetchAuthorizedUsers,
  selectAuthorizedDevices,
  deleteDevice,
  selectDevicesStatus,
  deleteDeviceStatus,
} from '../settingsSlice'
import PageLink from '../components/PageLink'

function DeviceManagement() {
  const classes = styles()
  const history = useHistory()
  const authorizedDevices = useSelector(selectAuthorizedDevices)
  const status = useSelector(selectDevicesStatus)
  const deleteStatus = useSelector(deleteDeviceStatus)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [target, setTarget] = React.useState(null)

  const handleDelete = (deviceID) => {
    setOpen(false)
    dispatch(deleteDevice(deviceID))
  }

  const refresh = () => {
    dispatch(fetchAuthorizedUsers())
  }

  const handleDialogOpen = (deviceID) => {
    setTarget(deviceID)
    setOpen(true)
  }

  React.useEffect(() => {
    if (deleteStatus === 'fulfilled') {
      dispatch(fetchAuthorizedUsers())
    }
  }, [deleteStatus])

  React.useEffect(() => {
    dispatch(fetchAuthorizedUsers())
  }, [])

  return (
    <Box className={classes.centerPage}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.sectionTitle}
        >
          <Grid item container direction="row" xs>
            <IconButton
              onClick={() => history.replace('/settings')}
              aria-label="Back"
              className={classes.backButton}
            >
              <ArrowBackIos fontSize="small" />
            </IconButton>
            <Typography variant="h1">
              <b>Device Management</b>
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={refresh}
              size="small"
              className={classes.refreshButton}
            >
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item className={classes.fullWidth}>
          {status === 'fulfilled' ? (
            <DevicesList
              authorizedDevices={authorizedDevices}
              dialogTarget={handleDialogOpen}
              classes={classes}
            />
          ) : (
            <Loading />
          )}
        </Grid>
        <Dialog open={open}>
          <DialogTitle>
            <Typography variant="h5">Confirm Delete</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this device?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDelete(target)}>Delete Device</Button>
            <Button onClick={() => setOpen(false)}>Keep Device</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  )
}
const DevicesList = ({ authorizedDevices, dialogTarget, classes }) => (
  <Card className={classes.linksCard}>
    {authorizedDevices && authorizedDevices.devices.length !== 0 ? (
      authorizedDevices.devices.map((device) => (
        <PageLink
          key={device.ID}
          title={device.Device}
          description={
            <>
              {device.biometric ? (
                <CheckCircleIcon
                  style={{
                    color: 'green',
                    verticalAlign: 'middle',
                    marginRight: 5,
                  }}
                />
              ) : (
                <CancelIcon
                  style={{
                    color: 'red',
                    verticalAlign: 'middle',
                    marginRight: 5,
                  }}
                />
              )}
              Biometrics are {device.biometric ? '' : 'not'} enabled on{' '}
              {device.DeviceType} device
            </>
          }
          icon={
            <IconButton onClick={() => dialogTarget(device.ID)}>
              <DeleteForeverIcon />
            </IconButton>
          }
        />
      ))
    ) : (
      <PageLink
        title="No devices"
        description="You currently have no devices"
        icon={null}
      />
    )}
  </Card>
)

export default DeviceManagement
