import * as React from 'react'

import { ButtonBase, Grid, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import IconPicker from '../../../features/apps/AppComponents/IconPicker/IconPicker'

const useStyles = makeStyles((theme) => ({
  emergencyInfoIcon: {
    padding: theme.spacing(1),
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
      backgroundColor: theme.palette.action.focus,
      borderRadius: '100%',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.button.hover,
      borderRadius: '25px',
    },
  },
}))

export default function SettingsLocalButtons({ history }) {
  const classes = useStyles()
  const handleEmergencyContactClick = () => {
    history.push('/settings/emergencyInformation')
  }

  return (
    <>
      <Grid item md={4}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingRight: 10 }}
        >
          <Grid item>
            <Tooltip title="Emergency Information">
              <ButtonBase
                onClick={() => handleEmergencyContactClick()}
                aria-label="Emergency Information"
                className={classes.emergencyInfoIcon}
              >
                <IconPicker
                  iconName="fa-shield-alt"
                  color="#c41f32"
                  size="3x"
                />
              </ButtonBase>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
