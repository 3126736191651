const roleChoices = [
  { label: 'All', value: '' },
  { label: 'ruStudent', value: 'ruStudent' },
  { label: 'ruEmployee', value: 'ruEmployee' },
  { label: 'ruFaculty', value: 'ruFaculty' },
  { label: 'ruMidYearEnroll', value: 'ruMidYearEnroll' },
  { label: 'employee', value: 'employee' },
  { label: 'ruStudentWorker', value: 'ruStudentWorker' },
  { label: 'ruAlumni', value: 'ruAlumni' },
  { label: 'ruRetiree', value: 'ruRetiree' },
  { label: 'ruAdmit', value: 'ruAdmit' },
  { label: 'ruAdmitComing', value: 'ruAdmitComing' },
  { label: 'ruEnrollment', value: 'ruEnrollment' },
  { label: 'ruGuest', value: 'ruGuest' },
  { label: 'rbhsStudent', value: 'rbhsStudent' },
  { label: 'rbhsEmployee', value: 'rbhsEmployee' },
  { label: 'rbhsFaculty', value: 'rbhsFaculty' },
  { label: 'rbhsStudentWorker', value: 'rbhsStudentWorker' },
  { label: 'rbhsAlumni', value: 'rbhsAlumni' },
  { label: 'rbhsRetiree', value: 'rbhsRetiree' },
  { label: 'rbhsAdmit', value: 'rbhsAdmit' },
  { label: 'rbhsAdmitComing', value: 'rbhsAdmitComing' },
  { label: 'ruParent', value: 'ruParent' },
  { label: 'guestMode', value: 'guestMode' },
]

const campusIDChoices = [
  { label: 'Unknown', value: '0' },
  { label: 'New Brunswick', value: '1' },
  { label: 'Newark ', value: '2' },
  { label: 'Camden ', value: '3' },
  { label: 'RBHS New Brunswick ', value: '4' },
  { label: 'RBHS Newark ', value: '5' },
  { label: 'Off Campus ', value: '6' },
]

const proxyChoices = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No ', value: 'No' },
  { label: 'Academic ', value: 'Academic' },
  { label: 'Parent ', value: 'Parent' },
  { label: 'Financial ', value: 'Financial' },
]

const categoryChoices = [
  { label: 'Academic', value: 1 },
  { label: 'Finance ', value: 2 },
  { label: 'HR ', value: 3 },
  { label: 'Campus ', value: 4 },
  { label: 'Health ', value: 5 },
  { label: 'Technology ', value: 6 },
  { label: 'School ', value: 7 },
  { label: 'Administration ', value: 8 },
  { label: 'Career ', value: 9 },
]

const levelChoices = [
  { label: 'U', value: 'U' },
  { label: 'G', value: 'G' },
]

const orgCodeChoices = [
  { label: 'SCHOOL OF ARTS AND SCIENCES', value: '01' },
  { label: 'LIVINGSTON COLLEGE', value: '02' },
  { label: 'MASON GROSS - UNDERGRADUATE', value: '07' },
  { label: 'MASON GROSS - GRADUATE PROGRAM', value: '08' },
  { label: 'BLOUSTEIN SCH PLAN & PUBLIC POLICY-UNDERGRAD', value: '10' },
  { label: 'SCHOOL OF ENVIRONMENTAL AND BIOLOGICAL SCIENCES', value: '11' },
  { label: 'SCHOOL OF ENGINEERING', value: '14' },
  { label: 'GRADUATE SCHOOL OF EDUCATION', value: '15' },
  { label: 'SCHOOL OF GRADUATE STUDIES', value: '16' },
  { label: 'SCHOOL OF COMMUNICATION AND INFORMATION-GRADUATE', value: '17' },
  { label: 'GRAD SCH APPLIED & PROF PSYCH', value: '18' },
  { label: 'SCHOOL OF SOCIAL WORK', value: '19' },
  { label: 'SCHOOL OF PUBLIC AFFAIRS & ADMINISTRATION', value: '20' },
  { label: 'NEWARK COLLEGE OF ARTS & SCIENCES', value: '21' },
  {
    label: 'RUTGERS BUSINESS SCHOOL-NEWARK/NEW BRUNSWICK-GRADUATE',
    value: '22',
  },
  { label: 'RUTGERS LAW SCHOOL (Newark)', value: '23' },
  { label: 'RUTGERS LAW SCHOOL (Camden)', value: '24' },
  { label: 'SCHOOL OF NURSING-UNDERGRADUATE', value: '25' },
  { label: 'GRADUATE SCHOOL - NEWARK', value: '26' },
  { label: 'SCHOOL OF CRIMINAL JUSTICE', value: '27' },
  { label: 'RUTGERS BUSINESS SCHOOL-NEWARK/NEW BRUNSWICK', value: '29' },
  { label: 'ERNEST MARIO SCHOOL OF PHARMACY-UNDERGRADUATE', value: '30' },
  { label: 'ERNEST MARIO SCHOOL OF PHARMACY-GRADUATE', value: '31' },
  {
    label: 'RUTGERS BUSINESS SCHOOL-NEWARK/NEW BRUNSWICK-UNDERGRAD',
    value: '33',
  },
  { label: 'BLOUSTEIN SCH PLAN & PUBLIC POLICY (GRAD)', value: '34' },
  { label: 'SCHOOL OF MANAGEMENT & LABOR RELATIONS', value: '37' },
  { label: 'SCHOOL OF MANAGEMENT & LABOR RELATIONS (GRAD)', value: '38' },
  { label: 'SCHOOL OF PUBLIC AFFAIRS AND ADMINISTRATION', value: '40' },
  { label: 'SCHOOL OF CRIMINAL JUSTICE', value: '47' },
  { label: 'CAMDEN COLLEGE OF ARTS & SCIENCES', value: '50' },
  { label: 'SCHOOL OF BUSINESS (UNDERGRAD) CAMDEN', value: '52' },
  { label: 'SCHOOL OF BUSINESS (GRAD) CAMDEN', value: '53' },
  { label: 'GRADUATE SCHOOL - CAMDEN', value: '56' },
  { label: 'SCHOOL OF NURSING - CAMDEN (UG)', value: '57' },
  { label: 'SCHOOL OF NURSING - CAMDEN (GRAD)', value: '58' },
  { label: 'UNIVERSITY COLLEGE - NEWARK', value: '62' },
  { label: 'UNIVERSITY COLLEGE - CAMDEN', value: '64' },
  { label: 'SCHOOL OF NURSING - NEW BRUNSWICK (UG)', value: '77' },
  { label: 'CONTINUOUS EDUCATION UNDERGRADUATE CREDIT', value: '80' },
  { label: 'CONTINUOUS EDUCATION GRADUATE CREDIT', value: '81' },
  { label: 'SUMMER SESSION - NEW BRUNSWICK', value: '90' },
  { label: 'SUMMER SESSION - NEWARK', value: '93' },
  { label: 'SUMMER SESSION - CAMDEN', value: '96' },
  { label: 'Dental - Post-Graduate', value: 'DG' },
  { label: 'Dental - Professional', value: 'DP' },
  { label: 'SGS - Newark', value: 'GN' },
  { label: 'SGS - Piscataway', value: 'GP' },
  { label: 'SHP - Graduate', value: 'HG' },
  { label: 'SHP - Undergraduate', value: 'HU' },
  { label: 'New Jersey Medical School', value: 'MN' },
  { label: 'Nursing - Graduate', value: 'NG' },
  { label: 'School of Public Health', value: 'PH' },
  { label: 'Robert Wood Johnson Medical', value: 'RW' },
]

const unitCodeChoices = [
  { label: 'SCHOOL OF ARTS AND SCIENCES', value: 1 },
  { label: 'LIVINGSTON COLLEGE', value: 2 },
  { label: 'SCHL OF COMMUNICATION & INFORMATION (U)', value: 4 },
  { label: 'DOUGLASS COLLEGE', value: 6 },
  { label: 'MASON GROSS - UNDERGRADUATE', value: 7 },
  { label: 'MASON GROSS - GRADUATE PROGRAM', value: 8 },
  { label: 'BLOUSTEIN SCH PLAN & PUBLIC POLICY (UG)', value: 10 },
  { label: 'SCHOOL OF ENVIRONMENTAL AND BIOLOGICAL SCIENCES', value: 11 },
  { label: 'RUTGERS COLLEGE', value: 12 },
  { label: 'SCHOOL OF ENGINEERING', value: 14 },
  { label: 'GRADUATE SCHOOL OF EDUCATION', value: 15 },
  { label: 'GRADUATE SCHOOL-NEW BRUNSWICK', value: 16 },
  { label: 'SCHL OF COMMUNICATION & INFORMATION (G)', value: 17 },
  { label: 'GRAD SCH APPLIED & PROF PSYCH', value: 18 },
  { label: 'SCHOOL OF SOCIAL WORK', value: 19 },
  { label: 'SCHOOL OF PUBLIC AFFAIRS AND ADMINISTRATION', value: 20 },
  { label: 'NEWARK COLLEGE OF ARTS AND SCIENCES', value: 21 },
  { label: 'RUTGERS BUSINESS SCHOOL - NWK/NB (GRAD)', value: 22 },
  { label: 'Rutgers Law School', value: 23 },
  { label: 'Rutgers Law School', value: 24 },
  { label: 'SCHOOL OF NURSING', value: 25 },
  { label: 'GRADUATE SCHOOL - NEWARK', value: 26 },
  { label: 'SCHOOL OF CRIMINAL JUSTICE', value: 27 },
  { label: 'RUTGERS BUSINESS SCHOOL - NWK/NB (UG)', value: 29 },
  { label: 'ERNEST MARIO SCHOOL OF PHARMACY (UNG)', value: 30 },
  { label: 'ERNEST MARIO SCHOOL OF PHARMACY (GRAD)', value: 31 },
  { label: 'RUTGERS BUSINESS SCHOOL-NWK/NB', value: 33 },
  { label: 'BLOUSTEIN SCH PLAN & PUBLIC POLICY', value: 34 },
  { label: 'SCHOOL OF MANAGEMENT AND LABOR RELATIONS', value: 37 },
  { label: 'SCHOOL OF MGMT & LABOR RELATIONS  (G)', value: 38 },
  { label: 'SCHOOL OF PUBLIC AFFAIRS AND ADMINISTRATION', value: 40 },
  { label: 'SCHOOL OF NURSING', value: 45 },
  { label: 'SCHOOL OF CRIMINAL JUSTICE', value: 47 },
  { label: 'CAMDEN COLLEGE OF ARTS AND SCIENCES', value: 50 },
  { label: 'SCHOOL OF BUSINESS (UNGRAD) CAMDEN', value: 52 },
  { label: 'SCHOOL OF BUSINESS (GRAD) CAMDEN', value: 53 },
  { label: 'GRADUATE SCHOOL - CAMDEN', value: 56 },
  { label: 'SCHOOL OF NURSING - CAMDEN (UG)', value: 57 },
  { label: 'SCHOOL OF NURSING - CAMDEN (GRAD)', value: 58 },
  { label: 'UNIVERSITY COLLEGE - NEW BRUNSWICK', value: 61 },
  { label: 'UNIVERSITY COLLEGE - NEWARK', value: 62 },
  { label: 'UNIVERSITY COLLEGE - CAMDEN', value: 64 },
  { label: 'ROBERT WOOD JOHNSON MEDICAL SCHOOL', value: 70 },
  { label: 'NEW JERSEY MEDICAL SCHOOL', value: 71 },
  { label: 'RUTGERS SCHOOL OF DENTAL MEDICINE', value: 72 },
  { label: 'SCHOOL OF HEALTH RELATED PROFESSIONS (UG)', value: 73 },
  { label: 'SCHOOL OF HEALTH RELATED PROFESSIONS (G)', value: 74 },
  { label: 'SCHOOL OF PUBLIC HEALTH', value: 75 },
  { label: 'GRADUATE SCHOOL OF BIOMEDICAL SCIENCES', value: 76 },
  { label: 'SCHOOL OF NURSING - NEW BRUNSWICK (UG)', value: 77 },
  { label: 'SCHOOL OF NURSING - NEW BRUNSWICK (GRAD)', value: 78 },
  { label: 'BIOPHARMA (G)', value: 79 },
  { label: 'CONTINUOUS EDUCATION AND UNDERGRADUATE STUDIES', value: 80 },
  { label: 'CONTINUOUS EDUCATION AND GRADUATE STUDIES', value: 81 },
  { label: 'CENTER FOR CONTINUING AND OUTREACH EDUCATION', value: 89 },
  { label: 'SUMMER/WINTER SESSION - NEW BRUNSWICK', value: 90 },
  { label: 'SUMMER/WINTER SESSION - NEWARK', value: 93 },
  { label: 'SUMMER/WINTER SESSION - CAMDEN', value: 96 },
]

// List and rules of widget keys for admin
export const keysToShowList = [
  {
    id: 'id',
    type: 'string',
    order: 1,
    editable: false,
    displayPriority: 3,
    defaultValue: '',
    options: null,
  },
  {
    id: 'active',
    type: 'bool',
    order: 2,
    editable: true,
    displayPriority: 1,
    defaultValue: false,
    options: null,
  },
  {
    id: 'default',
    type: 'bool',
    order: 3,
    editable: true,
    displayPriority: 1,
    defaultValue: false,
    options: null,
  },
  {
    id: 'title',
    type: 'string',
    order: 4,
    editable: true,
    displayPriority: 1,
    defaultValue: '',
    options: null,
  },
  {
    id: 'desc',
    type: 'string',
    order: 5,
    editable: true,
    displayPriority: 1,
    defaultValue: '',
    options: null,
  },

  {
    id: 'campusIds',
    type: 'multiSelectDropDown',
    order: 6,
    editable: true,
    displayPriority: 1,
    defaultValue: [],
    options: campusIDChoices,
  },
  {
    id: 'roles',
    type: 'multiSelectDropDown',
    order: 7,
    editable: true,
    displayPriority: 1,
    defaultValue: [],
    options: roleChoices,
  },
  {
    id: 'subscribable',
    type: 'multiSelectDropDown',
    order: 8,
    editable: true,
    displayPriority: 1,
    defaultValue: [],
    options: roleChoices,
  },
  {
    id: 'proxy',
    type: 'multiSelectDropDown',
    order: 9,
    editable: true,
    displayPriority: 1,
    defaultValue: '',
    options: proxyChoices,
  },
  {
    id: 'category',
    type: 'multiSelectDropDown',
    order: 10,
    editable: true,
    displayPriority: 1,
    defaultValue: '',
    options: categoryChoices,
  },
  {
    id: 'program',
    type: 'multiSelectDropDown',
    order: 11,
    editable: true,
    displayPriority: 1,
    defaultValue: [],
    options: null,
  },
  {
    id: 'level',
    type: 'multiSelectDropDown',
    order: 12,
    editable: true,
    displayPriority: 1,
    defaultValue: [],
    options: levelChoices,
  },
  {
    id: 'orgCode',
    type: 'multiSelectDropDown',
    order: 13,
    editable: true,
    displayPriority: 1,
    defaultValue: [],
    options: orgCodeChoices,
  },
  {
    id: 'unitCode',
    type: 'multiSelectDropDown',
    order: 14,
    editable: true,
    displayPriority: 1,
    defaultValue: [],
    options: unitCodeChoices,
  },

  {
    id: 'ws',
    type: 'list',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: [],
    options: null,
  },
  {
    id: 'showOnDashboard',
    type: 'bool',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: true,
    options: null,
  },
  {
    id: 'screenshot',
    type: 'string',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: '',
    options: null,
  },
  {
    id: 'order',
    type: 'int',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: 0,
    options: null,
  },
  {
    id: 'note_url',
    type: 'string',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: null,
    options: null,
  },
  {
    id: 'note_roles',
    type: 'list',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: [],
    options: null,
  },
  {
    id: 'note_position',
    type: 'string',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: null,
    options: null,
  },
  {
    id: 'note_msg',
    type: 'string',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: null,
    options: null,
  },
  {
    id: 'note_icon',
    type: 'string',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: null,
    options: null,
  },
  {
    id: 'note_fontColor',
    type: 'string',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: null,
    options: null,
  },
  {
    id: 'note_campusIds',
    type: 'list',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: [],
    options: null,
  },
  {
    id: 'note_background',
    type: 'string',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: null,
    options: null,
  },
  {
    id: 'manuallyMoved',
    type: 'int',
    order: 9999999,
    editable: false,
    displayPriority: 3,
    defaultValue: null,
    options: null,
  },
  {
    id: 'lock',
    type: 'bool',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: false,
    options: null,
  },
  {
    id: 'key',
    type: 'string',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: '',
    options: null,
  },
  {
    id: 'index',
    type: 'int',
    order: 9999999,
    editable: false,
    displayPriority: 3,
    defaultValue: 0,
    options: null,
  },
  {
    id: 'icon',
    type: 'string',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: '',
    options: null,
  },
  {
    id: 'dsp_apps',
    type: 'bool',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: false,
    options: null,
  },
  {
    id: 'dsp_note',
    type: 'bool',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: false,
    options: null,
  },
  {
    id: 'dsp_refresh',
    type: 'bool',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: false,
    options: null,
  },
  {
    id: 'dsp_refresh_time',
    type: 'bool',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: false,
    options: null,
  },
  {
    id: 'dsp_title',
    type: 'bool',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: '',
    options: null,
  },
  {
    id: 'custom',
    type: 'string',
    order: 9999999,
    editable: true,
    displayPriority: 3,
    defaultValue: '',
    options: null,
  },
]

// Takes keysToShowList and turns it into a dict
export const keysToShowForAdmin = Object.assign(
  {},
  ...keysToShowList.map((x) => ({ [x.id]: x })),
)

// Display Priority
// 1: Show on Admin Widget
// 2: Show on Add/Edit Widget
// 3: Don't show anywhere

export const createNewWidgetTemplate = () => {
  const newWidget = Object.assign(
    {},
    ...keysToShowList.map((x) => ({ [x.id]: x.defaultValue })),
  )
  return newWidget
}

export const widgetKeysInOrderList = (displayPriority) => {
  const sortedWidgets = keysToShowList.sort((a, b) => a.order - b.order)
  return sortedWidgets
    .filter((x) => x.displayPriority <= displayPriority)
    .map((x) => x.id)
}
