/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './App.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { useIdleTimer } from 'react-idle-timer'
import CssBaseline from '@material-ui/core/CssBaseline'
import Hidden from '@material-ui/core/Hidden'
import { PrivateRoute } from './app/helpers'
import { navigation } from './app/navigation/navigation.constants'
import { Login } from './app/features/login/Login'
import { theme } from './app/theme/theme'
import TopAppBar from './app/navigation/topAppBar/TopAppBar'
import BottomTabBar from './app/navigation/bottomTabBar/BottomTabBar'
import SideNav from './app/navigation/sideNav/SideNav'
import AdminPage from './app/features/admin/AdminPage'
import {
  fetchProfileLogEvents,
  resetProfileLogEvents,
} from './app/features/dashboard/widgets/MyProfile/myProfileSlice'
import IdleTimer from './app/features/IdleTimer/IdleTimer'
import {
  fetchSettings,
  selectDarkMode,
} from './app/features/settings/settingsSlice'
import PrivacyNotice from './app/features/settings/pages/PrivacyNotice'
import WidgetMockups from './app/features/dashboard/Pages/WidgetMockups/WidgetMockups'

function App() {
  // TODO - Change the seconds based on the confirmation of 3 hours OR 6 hours for idle timer
  const timeout = 7200000

  const [remaining, setRemaining] = React.useState(timeout)
  const [openAlert, setOpenAlert] = React.useState(false)

  const dispatch = useDispatch()
  const { userProfile } = useSelector((s) => s.myProfile)

  const onPrompt = () => {
    setOpenAlert(true)
  }

  const onAction = () => {
    // check if session cookie exists
    // if (
    //   window.location.hostname !== 'localhost' &&
    //   document.cookie.indexOf('myrJwtToken') === -1
    // ) {
    //   setOpenAlert(true)
    // }
    setRemaining(7200000)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle: onPrompt,
    onAction,
    timeout: remaining || timeout,
    debounce: 1000,
  })

  const { userEvents } = useSelector((s) => s.myProfile)

  //   const { data } = useSelector((s) => s.settings)
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (window.location.hostname !== 'localhost') {
        if (userEvents?.length > 0) {
          dispatch(fetchProfileLogEvents()).then(() => {
            // clear events array after to events
            dispatch(resetProfileLogEvents())
          })
        }
      }
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [userEvents])

  React.useEffect(() => {
    dispatch(fetchSettings())
  }, [])

  return (
    <div>
      <ThemeProvider theme={theme(useSelector(selectDarkMode))}>
        {openAlert === true ? (
          <IdleTimer
            openAlert={openAlert}
            dispatch={dispatch}
            userProfile={userProfile}
          />
        ) : null}
        <div>
          <CssBaseline />
          <Router basename={process.env.REACT_APP_BASENAME}>
            <TopAppBar />
            <Box height="100%">
              <Hidden smDown>
                <SideNav />
              </Hidden>
              <Switch>
                <Route exact component={Login} path="/login" />
                <Route exact component={AdminPage} path="/admin" />
                <Route
                  exact
                  component={PrivacyNotice}
                  path="/settings/appinfo/privacyNotices"
                />
                <Route exact component={WidgetMockups} path="/widget-mockups" />
                {navigation.routes.map((props) => (
                  <PrivateRoute
                    key={props.path}
                    exact={props.exact} // Needs to be false for nested navigation
                    path={props.path}
                    component={props.component}
                    {...props}
                  />
                ))}
                <Redirect from="*" to="/" />
              </Switch>
            </Box>
            <Hidden mdUp>
              <BottomTabBar />
            </Hidden>
          </Router>
        </div>
      </ThemeProvider>
    </div>
  )
}

export default App
