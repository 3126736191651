/* eslint-disable no-underscore-dangle */
/* eslint-disable no-prototype-builtins */
import React from 'react'
import { OverlayView } from '@react-google-maps/api'
import uuid from 'react-uuid'
import { useDispatch, useSelector } from 'react-redux'
import {
  faArchway,
  faCity,
  faBuilding,
  faSchool,
} from '@fortawesome/free-solid-svg-icons'
import IconPicker from '../../../../../apps/AppComponents/IconPicker/IconPicker'
import { updateBottomSheet, updateMapTabValue } from '../../redux/myMapsSlice'
import { handleViewBuildingInfoSheet } from '../../../Buildings/redux/myBuildingsSlice'
import CheckIsMobile from '../../../../../../../utils/isMobileHook'
import {
  BUILDING,
  CAMDENENGAGEBUILDING,
  NewBrunswick,
  Piscataway,
  Newark,
  Camden,
  OTHER,
} from '../../../../utils/constants'

const campusIcon = {
  [NewBrunswick]: { icon: faArchway, color: '#cc0033' },
  [Piscataway]: { icon: faArchway, color: '#cc0033' },
  [Newark]: { icon: faCity, color: '#b65000' },
  [Camden]: { icon: faBuilding, color: '#c78001' },
  [OTHER]: { icon: faSchool, color: '#c7c7c7' },
}

function BuildingMarker(props) {
  const { building } = props
  const { component } = useSelector((s) => s.maps.bottomSheet)
  const dispatch = useDispatch()
  const isMobile = CheckIsMobile()
  const [selectedCampus, setSelectedCampus] = React.useState(null)

  const getCampusInfo = (city) => {
    const formattedCity = city?.trim().toLowerCase()
    const campusIconsFormatted = Object.keys(campusIcon).reduce((acc, key) => {
      acc[key.toLowerCase()] = campusIcon[key]
      return acc
    }, {})
    return campusIconsFormatted[formattedCity] || campusIconsFormatted.other
  }

  const handleClick = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const venueWebsite = urlParams.get('venueWebsite')
    // maps -> bottomSheet -> component === "CAMDENENGAGENBUILDING"
    if (component === CAMDENENGAGEBUILDING) {
      window.open(venueWebsite, '_blank')
    } else if (isMobile) {
      dispatch(updateMapTabValue(1))
    } else {
      dispatch(handleViewBuildingInfoSheet(building.bid))
      dispatch(updateBottomSheet(BUILDING))
    }

    setSelectedCampus(building.city)
  }

  const { icon, color } = getCampusInfo(selectedCampus || building.city)

  return (
    <button
      type="button"
      aria-label="Building Marker"
      onClick={() => handleClick()}
    >
      <OverlayView
        key={uuid()}
        position={{
          lat: building.lat,
          lng: building.lng,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <IconPicker iconName={icon?.iconName} color={color} size="2x" />
      </OverlayView>
    </button>
  )
}

export default BuildingMarker
