import React from 'react'
import Button from '@material-ui/core/Button'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import styles from './HideShowButtonStyles'
import { selectShowSensitiveInfo } from '../../../settings/settingsSlice'
import { logDashboardEvent } from '../../events'

function HideShowButton({
  displayData,
  color = 'red',
  textColor = 'white',
  widgetId,
  dispatch,
  moduleName,
  additionalVoiceOver = '',
  width = 210,
  height = 40,
  borderRadius = 20,
  handleShowHideShowButton,
}) {
  const showSensitiveInfo = useSelector(selectShowSensitiveInfo)
  const classes = styles()
  const [showData, setShowData] = React.useState(showSensitiveInfo)
  const toggleShow = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        !showData ? 'Show' : 'Hide',
        '',
      )
    }
    setShowData(!showData)
    if (handleShowHideShowButton) {
      handleShowHideShowButton()
    }
  }
  React.useEffect(() => {
    setShowData(showSensitiveInfo)
  }, [showSensitiveInfo])
  React.useEffect(() => {
    if (handleShowHideShowButton) {
      setShowData(true)
    }
  }, [])
  return (
    <Button
      onClick={toggleShow}
      style={{
        background: color,
        color: textColor,
        width,
        height,
        borderRadius,
      }}
      aria-label={`${
        showData ? additionalVoiceOver : ''
      } Hide/Show ${moduleName}`}
    >
      {showData ? (
        <Typography variant="h3">{displayData}</Typography>
      ) : (
        <VisibilityOff className={classes.icon} />
      )}
    </Button>
  )
}

export default HideShowButton
