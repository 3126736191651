import React, { useState, useRef } from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@material-ui/core'

import { focusAlert, selectMyAlertsRef } from '../MyAlertsSlice'
import AlertList from './AlertList'
import {
  RutgersTab,
  RutgersTabs,
} from '../../../DashboardComponents/Tabs/RutgersTabs'
import { TabPanel } from '../../../DashboardComponents/TabPanel/TabPanel'

function AlertGUI({ data, classes, dialogHandler }) {
  const [value, setValue] = useState(0)
  const {
    personalAlerts,
    emergencyAlerts,
    itAlerts,
    refundAlerts,
    customNotifications,
  } = data.alerts
  const [activeLabel, historyLabel] = data.categories
  const personalLabel = <Typography variant="body1">{activeLabel}</Typography>
  const emergencyLabel = <Typography variant="body1">{historyLabel}</Typography>
  const dispatch = useDispatch()
  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  const [activeList, setActiveList] = React.useState([])
  const [historyList, setHistoryList] = React.useState([])

  React.useEffect(() => {
    // Removed ...refundAlerts for now 3/29/24
    const combinedList = [
      ...(Array.isArray(personalAlerts) ? personalAlerts : []),
      ...(Array.isArray(itAlerts) ? itAlerts : []),
      ...(Array.isArray(refundAlerts) ? refundAlerts : []),
      ...(Array.isArray(customNotifications) ? customNotifications : []),
    ]

    let newerActiveList = combinedList.filter(
      (alert) => alert.acknowledgedTS === '',
    )
    newerActiveList = [...(emergencyAlerts || []), ...newerActiveList]
    setActiveList(newerActiveList?.filter((item) => item))
    const newHistoryList = combinedList?.filter(
      (alert) => alert.acknowledgedTS !== '',
    )
    setHistoryList(newHistoryList?.filter((item) => item))
  }, [data.alerts])

  const alertsRef = useRef(null)
  const focusAlertsNotifier = useSelector(selectMyAlertsRef)
  React.useEffect(() => {
    if (focusAlertsNotifier) {
      alertsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
      dispatch(focusAlert())
    }
  }, [focusAlertsNotifier])

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.retainer}
      ref={alertsRef}
      sx={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid item className={classes.modalTab}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <RutgersTabs
            value={value}
            onChange={handleChange}
            style={{ minWidth: '100%' }}
          >
            <RutgersTab
              label={personalLabel}
              onFocus={() => {}}
              tabIndex={0}
              style={{ minWidth: '50%' }}
            />
            <RutgersTab
              label={emergencyLabel}
              onFocus={() => {}}
              tabIndex={0}
              style={{ minWidth: '50%' }}
            />
          </RutgersTabs>
        </Box>
      </Grid>
      <div
        className={
          value === 0
            ? classes.alertsListContainer
            : classes.alertsListContainerHistory
        }
      >
        <TabPanel value={value} index={0}>
          <AlertList
            alertList={activeList}
            classes={classes}
            dialogHandler={dialogHandler}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AlertList
            alertList={historyList}
            classes={classes}
            dialogHandler={dialogHandler}
            alertHistory
            dispatch={dispatch}
          />
        </TabPanel>
      </div>
    </Grid>
  )
}

export default AlertGUI
