/* eslint-disable camelcase */
/* eslint-disable no-else-return */
import * as React from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography as Text,
  ListItemSecondaryAction,
  IconButton,
  SvgIcon,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { useDispatch, useSelector } from 'react-redux'

import {
  handleSearchBarToggle,
  removeStopFromFavorite,
  addStopToFavorite,
  setSelectedFavoritedStops,
  handleStopClicked,
} from '../../../sections/Map/redux/myMapsSlice'
import { stopsListComponentStyles } from './stopsListComponentStyles'
import { sortListAlphabetically } from '../../../utils/helpers'
import { logMapEvent } from '../../../utils/events'
import {
  CAMDEN_COLOR,
  NEW_BRUNSWICK_COLOR,
  NEWARK_COLOR,
  STOPMARKER,
  STOPSFAVORITE,
  STOPSUNFAVORITE,
} from '../../../utils/constants'
import { storeFavoriteStopsListToFirebase } from '../../../sections/Map/firebase/firebase'

const inactivePathIcon =
  'M97.852 21.895 C 77.160 23.907,60.636 40.633,58.593 61.632 C 58.098 66.721,58.098 260.232,58.593 265.321 C 60.930 289.348,79.768 305.412,105.664 305.463 L 109.570 305.470 112.109 308.692 C 125.738 325.986,147.285 348.132,164.060 362.089 C 192.502 385.752,207.719 385.805,235.938 362.340 C 252.233 348.790,273.249 327.382,286.647 310.686 L 290.820 305.484 294.727 305.470 C 320.673 305.373,339.460 289.349,341.798 265.321 C 342.293 260.232,342.293 66.721,341.798 61.632 C 339.729 40.367,323.304 23.943,302.040 21.874 C 297.453 21.428,102.449 21.448,97.852 21.895 M212.188 61.517 C 292.349 70.894,330.930 165.201,280.347 228.125 C 231.124 289.357,133.896 274.485,104.723 201.262 C 76.260 129.818,135.668 52.566,212.188 61.517 M194.922 92.993 C 141.457 96.440,111.328 158.323,141.326 203.076 C 174.182 252.093,249.350 241.875,267.743 185.891 C 283.592 137.650,246.005 89.700,194.922 92.993'

const campusColors = {
  newBrunswick: NEW_BRUNSWICK_COLOR,
  newark: NEWARK_COLOR,
  camden: CAMDEN_COLOR,
}
const determineColor = (stop_lat, stop_lon) => {
  if (
    stop_lat >= 39.942298899 &&
    stop_lat <= 39.953253 &&
    stop_lon >= -75.126481 &&
    stop_lon <= -75.119865832
  ) {
    return 'camden'
  } else if (
    stop_lat >= 40.734819 &&
    stop_lat <= 40.746906 &&
    stop_lon >= -74.192475249 &&
    stop_lon <= -74.164721
  ) {
    return 'newark'
  } else {
    return 'newBrunswick'
  }
}
const StopsListComponent = ({ stop, style, isFavorited }) => {
  const classes = stopsListComponentStyles()
  const dispatch = useDispatch()
  const { routes, favoritedStops } = useSelector((s) => s.maps)
  const [favorited, setFavorited] = React.useState(isFavorited)
  const [routesList, updateRoutesList] = React.useState([])

  React.useEffect(() => {
    // default to favorited
    const isFavoritedFound = favoritedStops?.find(
      (favoritedStop) => favoritedStop.stop_id === stop.stop_id,
    )
    if (isFavoritedFound) {
      setFavorited(true)
    } else {
      setFavorited(false)
    }
  }, [favoritedStops, stop])

  React.useEffect(() => {
    const routesListArray = []
    stop.routes?.forEach((stopRoute) => {
      routes
        .map((route) => {
          if (route.route_id === stopRoute) {
            // return route.long_name
            return routesListArray.push(route.long_name)
          }
          return null
        })
        .filter((item) => item !== null)
    })
    updateRoutesList(routesListArray)
  }, [routes])

  const isValidSegments = () => true

  const viewStopInfo = () => {
    dispatch(handleSearchBarToggle(false))
    if (isValidSegments()) {
      logMapEvent(dispatch, STOPMARKER, stop.stop_name)
      dispatch(handleStopClicked(stop))
    }
  }

  const addToFavorite = (e) => {
    e.stopPropagation()
    let newFavoriteList = []
    setFavorited(!favorited)
    if (favorited) {
      // If already favorited, remove
      newFavoriteList = favoritedStops.filter((s) => s.stop_id !== stop.stop_id)
      // Add stop back to other list

      dispatch(
        removeStopFromFavorite(
          sortListAlphabetically(newFavoriteList, 'stop_name'),
        ),
      )
    } else {
      // To do add building to list
      newFavoriteList = [...favoritedStops]
      newFavoriteList.push(stop)

      dispatch(addStopToFavorite(newFavoriteList))
      //   setFavorited(true)
    }
    dispatch(
      setSelectedFavoritedStops(
        sortListAlphabetically(newFavoriteList, 'stop_name'),
      ),
    )

    storeFavoriteStopsListToFirebase(newFavoriteList.map((s) => s.stop_id))
    logMapEvent(
      dispatch,
      favorited ? STOPSUNFAVORITE : STOPSFAVORITE,
      stop.stop_name,
    )
  }
  return (
    <ListItem
      button
      onClick={viewStopInfo}
      key={stop.stop_id}
      style={style}
      className={classes.list}
    >
      <ListItemIcon style={{ width: '100%' }}>
        <SvgIcon
          viewBox="0 0 512 512"
          style={{ fontSize: 34, paddingTop: '4px' }}
          htmlColor={campusColors[determineColor(stop.stop_lat, stop.stop_lon)]}
        >
          <path d={inactivePathIcon} />
        </SvgIcon>
        <ListItemText
          disableTypography
          primary={
            <Text type="body2" className={classes.busStopText}>
              {stop.stop_name}
            </Text>
          }
          secondary={
            <Text type="body2" className={classes.busStopSecondaryText}>
              {routesList.length > 0 ? routesList.join(', ') : null}
            </Text>
          }
        />
      </ListItemIcon>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="add/remove favorited building"
          onClick={addToFavorite}
        >
          {favorited ? (
            <FavoriteIcon className={classes.favoriteIconSelected} />
          ) : (
            <FavoriteBorderIcon className={classes.iconButtonBorder} />
          )}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default StopsListComponent
