import { makeStyles } from '@material-ui/core/styles'
import { customColor } from '../../../../theme/theme'
// TODO Bring Back Unique Colors
export const tempStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  subtitleSpan: {
    fontWeight: 'normal',
  },
  selectContainer: {
    width: 320,
    marginTop: 7,
  },
  selector: {
    width: '100%',
    height: 35,
  },
  greyBackground: {
    backgroundColor: theme.palette.background.default,
  },
  ruListContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    borderBottomLeftRadius: 4,
    borderRightLeftRadius: 4,
    height: 362, // minus 50
    width: 335,
  },
  rbhsListContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    borderBottomLeftRadius: 4,
    borderRightLeftRadius: 4,
    height: 360, // minus 50
    width: 335,
  },
  bothruListContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    borderBottomLeftRadius: 4,
    borderRightLeftRadius: 4,
    height: 325, // minus 50
    width: 335,
  },
  bothrbhsListContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    borderBottomLeftRadius: 4,
    borderRightLeftRadius: 4,
    height: 325, // minus 50
    width: 335,
  },
  bottomListContainer: {
    display: 'flex',
    height: 50,
  },
  bottomListText: {
    color: theme.palette.primary.headerText,
    fontSize: 12,
  },
  bottomListButton: {
    height: '100%',
  },
  text: {
    fontWeight: 700,
  },
  sectionTitle: {
    marginRight: 10,
    fontSize: 12,
    fontWeight: 700,
  },
  rowSection: {
    paddingLeft: 8,
  },
  rightInfoContainer: {
    marginLeft: '1rem',
  },
  icon: {
    color: customColor.rutgersRed,
  },
  table: {
    minWidth: 200,
  },
  tableAdditionalHeader: {
    fontWeight: 'bold',
  },
  courseScheduleDialog: {
    height: '100%',
    [theme.breakpoints.down(570)]: {
      height: 'calc(100vh - 55px) !important',
    },
  },
  courseAnchor: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '&:hover': {
      color: '#ffffff',
    },
  },
  award: {
    marginTop: 15,
    margin: 10,
    padding: 5,
    backgroundColor: theme.palette.backgroundColor.ternary,
  },
  titleStyle: {
    fontWeight: 'bold',
  },
}))

export default tempStyles
